.letterSpacing {
  letter-spacing: 0.1px;
  font-family: "Open Sans", sans-serif;
}
.border {
  border: 1px solid #bac2b7;
}
.productContent .productInfo {
  display: flex;
  flex-direction: column;
  padding: 3% 3% 0 3%;
  color: #494646;
}
.productContent .productInfo .productPrice {
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.1px;
  font-family: "Open Sans", sans-serif;
}
.productContent .productInfo h6 {
  letter-spacing: 0.1px;
  font-family: "Open Sans", sans-serif;
  line-height: 1.25rem;
}
.productContent .productInfo span {
  color: #006f45;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
  font-family: "Open Sans", sans-serif;
}
.productContent .productInfo .productDescription {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.1875rem;
  overflow: hidden !important;
  text-overflow: ellipsis;
  letter-spacing: 0.1px;
  font-family: "Open Sans", sans-serif;
}
.productContent .productInfo .ProductRating {
  width: 1rem;
}
.productContent .productInfo .ProductRating:before,
.productContent .productInfo .ProductRating:after {
  font-size: 1rem;
  line-height: 1.5rem;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
#root,
.App {
  margin: 0;
  padding: 0;
  border: 0;
}
h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 2.25rem;
}
h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
}
h3 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
}
h4 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
}
h5 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 1rem;
}
h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
}
p {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem;
}
b {
  font-weight: bold;
}
::-webkit-scrollbar {
  display: none;
}
.mediumInput {
  width: 49%;
}
.largeInput {
  width: 100%;
}
.row {
  display: flex;
  justify-content: space-between;
}
.primaryButton .MuiButtonBase-root,
.primaryButton .MuiButton-root {
  color: #494646;
  background-color: #fbd200;
  margin-top: 20px;
}
.primaryButton .MuiButtonBase-root:hover,
.primaryButton .MuiButton-root:hover {
  background-color: #fac400;
}
.primaryButton .MuiButtonBase-root:focus,
.primaryButton .MuiButton-root:focus {
  background-color: #edba00;
}
.secondaryButton .MuiButtonBase-root,
.secondaryButton .MuiButton-root {
  color: #faf9f9;
  background-color: #006f45;
  width: 300px;
  margin-top: 10px;
}
.secondaryButton .MuiButtonBase-root:hover,
.secondaryButton .MuiButton-root:hover {
  background-color: #0e8a5b;
}
.secondaryButton .MuiButtonBase-root:focus,
.secondaryButton .MuiButton-root:focus {
  background-color: #227052;
}
.center {
  margin: 0 auto;
}
.centerText {
  text-align: center;
}
.mediumButton .MuiButton-root {
  width: 200px;
  font-size: 14px;
}
.oppositeButton .MuiButtonBase-root,
.oppositeButton .MuiButton-root {
  color: #494646;
  background-color: white;
  border: 1px solid #fbd200;
  margin-top: 10px;
}
.oppositeButton .MuiButtonBase-root:hover,
.oppositeButton .MuiButton-root:hover {
  border: 2px solid #fac400;
  background-color: white;
}
.oppositeButton .MuiButtonBase-root:focus,
.oppositeButton .MuiButton-root:focus {
  border: 2px solid #edba00;
  background-color: white;
}
.hidden {
  visibility: hidden;
}
.disabled {
  opacity: 0.3;
}
.mediumSelect {
  width: 49%;
}
.mediumSelect .css-1gcnffg-MuiInputBase-root-MuiOutlinedInput-root {
  margin: 10px 0;
}
.mediumSelect .css-1y4juvx-MuiFormLabel-root-MuiInputLabel-root {
  margin: 8px;
  font-size: 16px;
}
.mediumSelect .css-1w9gngf-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1w9gngf-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1w9gngf-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 16px;
}
.smallInput {
  width: 32%;
  margin: 0 2px;
}
.chipsDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5;
  margin-top: 10px;
}
.buttonRow {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.css-tjsfp3-MuiSvgIcon-root {
  width: 100%;
  height: 100%;
}
.cardButton .MuiButtonBase-root,
.cardButton .MuiButton-root {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.centeredRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.disabled {
  opacity: 0.3;
}
.transparentPrimaryButton .MuiButtonBase-root,
.transparentPrimaryButton .MuiButton-root {
  color: white;
  background-color: transparent;
  border: 1px solid #fbd200;
  margin-top: 10px;
}
.transparentPrimaryButton .MuiButtonBase-root:hover,
.transparentPrimaryButton .MuiButton-root:hover {
  border: 2px solid #fac400;
}
.transparentPrimaryButton .MuiButtonBase-root:focus,
.transparentPrimaryButton .MuiButton-root:focus {
  border: 2px solid #edba00;
}
.notSignedContainer {
  width: 480px;
  border: 1px solid #90a18a;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.notSignedContainer img {
  padding: 5%;
}
.notSignedContainer p {
  text-align: center;
  margin: 5%;
}
.notSignedContainer .buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}
.notSignedContainer .buttons button {
  margin: 5% 3%;
}
.registerWrapper {
  margin: 250px auto;
  background-image: url(https://res.cloudinary.com/tripp4077/image/upload/v1608318614/farm-era-public-images/signup_h5qgmw.jpg);
  width: 100%;
  max-width: 1200px;
  height: fit-content;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.registerForm {
  width: 55%;
  height: 590px;
  background: rgba(255, 255, 255, 0.6);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin-left: 45%;
  justify-content: center;
  /* Add a green text color and a checkmark when the requirements are right */
}
.registerForm .registerPassword {
  width: 49%;
  /* The message box is shown when the user clicks on the password field */
}
.registerForm .loginWrapper {
  margin: 250px auto;
  background-image: url(https://res.cloudinary.com/tripp4077/image/upload/v1608318605/farm-era-public-images/login_sip0qk.jpg);
  width: 100%;
  max-width: 1200px;
  height: fit-content;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.registerForm .loginWrapper .error {
  color: red;
  font-weight: bold;
}
.registerForm .loginWrapper .loginForm {
  width: 55%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 45%;
  padding: 30px;
}
.registerForm .loginWrapper .loginForm .modalBody {
  display: flex;
}
.registerForm .loginWrapper .loginForm .modalBody button {
  background: transparent;
  font-size: 12px;
  margin: 10px auto;
  font-weight: normal;
}
.registerForm .buttonLink {
  border: none;
  background-color: white;
  color: #0054bc;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin: 20px 0;
}
.registerForm .editAddressModal {
  position: absolute;
  background: white;
  width: 700px;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}
.registerForm .editAddressModal .editAddressBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 20px;
}
.registerForm .editAddressModal .editAddressBody .buttonRow {
  display: flex;
  margin: 20px auto;
}
.registerForm .editPasswordModal {
  position: absolute;
  background: white;
  width: 700px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}
.registerForm .editPasswordModal .editPasswordBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 20px;
}
.registerForm .editPasswordModal .editPasswordBody .buttonRow {
  display: flex;
  margin: 20px auto;
}
.registerForm .editAccountModal {
  position: absolute;
  background: white;
  width: 700px;
  height: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}
.registerForm .editAccountModal .editAccountBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 20px;
}
.registerForm .editAccountModal .editAccountBody .buttonRow {
  display: flex;
  margin: 20px auto;
}
.registerForm .profilePage {
  width: 100%;
  margin: 0 300px;
}
.registerForm .profilePage .addressInfoDiv,
.registerForm .profilePage .passwordInfoDiv,
.registerForm .profilePage .accountInfoDiv {
  border: 1px solid #bac2b7;
  padding: 20px;
  width: 400px;
  margin: 20px 0;
}
.registerForm .profilePage .addressInfoDiv h3,
.registerForm .profilePage .passwordInfoDiv h3,
.registerForm .profilePage .accountInfoDiv h3 {
  padding-bottom: 20px;
}
.registerForm .profilePage .addressInfoDiv p,
.registerForm .profilePage .passwordInfoDiv p,
.registerForm .profilePage .accountInfoDiv p {
  font-size: 1rem;
  line-height: 1.5rem;
}
.registerForm #message {
  display: none;
  background: rgba(255, 255, 255, 0.1);
  color: #000;
  position: relative;
  padding: 20px;
  margin-top: 10px;
  width: fit-content;
  flex-direction: column;
}
.registerForm #message p {
  padding: 10px 35px;
  font-size: 14px;
}
.registerForm .valid {
  color: green;
}
.registerForm .valid:before {
  position: relative;
  left: -10px;
  content: '\2714';
}
.registerForm .invalid {
  color: red;
}
.registerForm .invalid:before {
  position: relative;
  left: -10px;
  content: '\2716';
}
/* Add a red text color and an "x" icon when the requirements are wrong */
#message {
  display: none;
  background: transparent;
  color: #000;
  position: relative;
  padding: 20px;
  margin-top: 10px;
  z-index: 20;
}
#message p {
  padding: 10px 35px;
  font-size: 14px;
}
/* Add a green text color and a checkmark when the requirements are right */
.valid {
  color: green;
}
.valid:before {
  position: relative;
  left: -10px;
  content: '\2714';
}
/* Add a red text color and an "x" icon when the requirements are wrong */
.invalid {
  color: red;
}
.invalid:before {
  position: relative;
  left: -10px;
  content: '\2716';
}
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
}
.breadcrumbs span {
  font-size: 0.75rem;
  letter-spacing: 0.1px;
  font-family: "Open Sans", sans-serif;
  line-height: 1.125rem;
  text-transform: capitalize;
  color: #494646;
}
.cloudinaryDiv {
  width: 120px;
  height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #90a18a;
}
.cloudinaryDiv img {
  width: 18px;
  height: 18px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  overflow-y: auto;
}
.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}
.display-block {
  display: block;
  overflow: scroll;
}
.display-none {
  display: none;
}
.cropContainer {
  position: absolute;
  top: -100px;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.137);
}
.cropContainer img {
  border: 1px solid white;
}
.cropAreaStyle {
  border: 1px solid hotpink;
}
.controls {
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: white;
}
.controls button {
  margin-bottom: 30px;
}
.controls .sliderContainer {
  width: 200px;
  height: 80px;
}
.controls .sliderContainer p {
  color: #494646;
  text-align: center;
  margin-top: 30px;
}
.controls .sliderContainer .slider {
  color: #fbd200;
}
.addProductPhotoDiv .modal-main {
  width: 500px;
  height: 700px;
}
.shoppingNav {
  color: #494646;
  border-bottom: 1px solid rgba(186, 194, 183);
  width: 100%;
  background: #fff;
}
.shoppingNav .MuiPaper-root {
  background: white;
}
.shoppingNav .MuiToolbar-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 210px;
  width: 80%;
  margin: auto;
}
.shoppingNav .navBarTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
.shoppingNav .navBarTop .locationBox {
  display: flex;
  align-items: center;
}
.shoppingNav .navBarTop .mapIcon {
  font-size: 18px;
  color: #0054bc;
}
.shoppingNav .navBarTop .stackedLogo {
  font-size: 100px;
}
.shoppingNav .navBarTop .iconBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
}
.shoppingNav .navBarTop .iconBox .MuiSvgIcon-root {
  font-size: 70px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shoppingNav .navBarMiddle {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shoppingNav .navBarMiddle .MuiTextField-root {
  width: 85%;
}
.shoppingNav .navBarMiddle .MuiButton-root {
  background: #fbd200;
  color: #494646;
  height: 40px;
}
.shoppingNav .navBarMiddle .MuiButton-root:hover {
  background: #fac400;
}
.shoppingNav .navBarMiddle .MuiButton-root:active {
  background: #edba00;
}
.shoppingNav .navBarBottom {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 10px 0;
}
.shoppingNav .navBarBottom .MuiChip-root {
  background: #fff;
  border: 2px solid #fbd200;
  padding: 5px 10px;
}
.myAccountNav {
  color: #494646;
  border-bottom: 1px solid rgba(186, 194, 183);
  width: 100%;
  background: #fff;
}
.myAccountNav .MuiPaper-root {
  background: white;
}
.myAccountNav .MuiToolbar-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  width: 80%;
  margin: auto;
}
.myAccountNav .navBarTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
.myAccountNav .navBarTop .locationBox {
  display: flex;
  align-items: center;
}
.myAccountNav .navBarTop .mapIcon {
  font-size: 18px;
  color: #0054bc;
}
.myAccountNav .navBarTop .stackedLogo {
  font-size: 100px;
}
.myAccountNav .navBarTop .iconBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
}
.myAccountNav .navBarTop .iconBox .MuiSvgIcon-root {
  font-size: 70px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.farmerNavDiv {
  color: #494646;
  border-bottom: 1px solid rgba(186, 194, 183);
  width: 100%;
  background: #fff;
}
.farmerNavDiv .MuiToolbar-root {
  display: flex;
  justify-content: space-between;
  height: 120px;
  width: 80%;
}
.farmerNavDiv .stackedLogo {
  font-size: 100px;
}
.farmerNavDiv .iconBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
}
.farmerNavDiv .iconBox .MuiSvgIcon-root {
  font-size: 70px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stackedLogo {
  font-size: 100px;
}
.editFarmButtons {
  display: flex;
}
.farmDashboardBox {
  margin-top: 130px;
}
.addProductDiv {
  width: 45%;
  margin: auto;
}
.accordionDetails {
  padding: 0 0 0 15px;
}
.farmSideNavDrawer {
  margin: 20px 0;
  flex-shrink: 0;
  z-index: 0;
}
.farmSideNavDrawer .MuiDrawer-paper {
  box-sizing: border-box;
  background-color: #eaede9;
}
.farmSideNavDrawer .MuiDrawer-paper .css-15oc4is-MuiToolbar-root {
  margin-top: 50px;
  width: 250px;
}
.farmSideNavDrawer .MuiDrawer-paper .farmSideNavAccordion {
  background-color: #eaede9;
}
.farmSideNavDrawer .MuiDrawer-paper .farmSideNavAccordion .farmSideNavP {
  font-size: 18px;
  font-family: 'Open Sans';
}
.farmSideNavDrawer .MuiListItemText-primary {
  color: #006f45;
  font-size: 18px;
}
.farmSideNavDrawer .farmSideNavIcon {
  min-width: 30px;
}
.farmDashboardPage .listingsContainer {
  margin: 140px 200px 0 280px;
  display: flex;
  flex-direction: column;
}
.farmDashboardPage .listingsContainer .listingsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.farmDashboardPage .listingsContainer .listingsBoxWrapper {
  display: flex;
  flex-direction: column;
}
.farmDashboardPage .listingsContainer .listingsBoxWrapper .iconsWrapper {
  display: flex;
  height: 100px;
  align-items: center;
}
.farmDashboardPage .listingsContainer .listingsBoxWrapper .iconBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
}
.farmDashboardPage .listingsContainer .listingsBoxWrapper .listingsBox {
  display: flex;
}
.farmDashboardPage .listingsContainer .listingsBoxWrapper .listingBox {
  background-color: rgba(252, 253, 251, 0.938);
  width: 310px;
  margin: 0 20px 0 0;
  border-radius: 8px;
  border: none;
}
.farmDashboardPage .listingsContainer .listingsBoxWrapper .listingContent {
  padding: 10px 20px;
}
.farmDashboardPage .listingsContainer .listingsBoxWrapper .listingContent .listingContentBox {
  display: flex;
  justify-content: space-between;
}
.farmDashboardPage .listingsContainer .listingsBoxWrapper .divider {
  color: #eaede9;
}
.farmDashboardPage .listingsContainer .listingsBoxWrapper .listingsActions {
  display: flex;
  justify-content: space-between;
  width: 50%;
  padding: 20px;
}
.filterDiv {
  width: 19%;
  padding: 1%;
  height: fit-content;
  border: 1px solid #bac2b7;
}
.filterDiv .filterLocation {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid orange;
}
.filterDiv .categoriesDiv,
.filterDiv .dietaryDiv {
  display: flex;
  flex-direction: column;
}
.filterDiv .categoriesDiv h5,
.filterDiv .dietaryDiv h5 {
  margin-bottom: 4%;
}
.filterDiv .categoriesDiv span,
.filterDiv .dietaryDiv span {
  margin: 3%;
}
.filterDiv .categoriesDiv .MuiFormControlLabel-label,
.filterDiv .dietaryDiv .MuiFormControlLabel-label {
  font-size: 1rem;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}
.filterDiv .farmLocDiv,
.filterDiv .priceDiv {
  width: 100%;
  margin: 5% 0;
}
.filterDiv .farmLocDiv .textField,
.filterDiv .priceDiv .textField {
  margin: 0 5px;
}
.filterDiv .farmLocDiv .MuiOutlinedInput-root,
.filterDiv .priceDiv .MuiOutlinedInput-root {
  font-size: 12px;
  width: 90px;
  height: 40px;
  min-height: 40px;
  margin: 0 1%;
}
.filterDiv .farmLocDiv .MuiOutlinedInput-root .MuiOutlinedInput-input,
.filterDiv .priceDiv .MuiOutlinedInput-root .MuiOutlinedInput-input {
  overflow: visible;
  white-space: pre-wrap;
  text-overflow: inherit;
}
.filterDiv .farmLocDiv .priceRow,
.filterDiv .priceDiv .priceRow {
  display: flex;
  justify-content: space-between;
  width: 280px;
  align-items: center;
  font-size: 14px;
}
.filterDiv .farmLocDiv .locationRow,
.filterDiv .priceDiv .locationRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 14px;
}
.productPageBox {
  margin: 0 auto;
  margin-top: 250px;
}
.productPageMoreItems .moreItemText {
  margin: 10px;
}
.productPageMoreItems .moreItemsBox {
  display: flex;
  width: 99%;
}
.productPageMoreItems .moreItemsBox .productCard {
  min-width: 265px;
  width: 265px;
  height: 380px;
  margin: 0 20px 0 ;
  border-radius: 4px;
  position: relative;
}
.productPageMoreItems .moreItemsBox .productCard .MuiButtonBase-root,
.productPageMoreItems .moreItemsBox .productCard .MuiButton-root {
  visibility: hidden;
}
.productList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.productList .productCard {
  min-width: 265px;
  width: 265px;
  height: 520px;
  margin: 0 10px 30px 20px;
  border-radius: 4px;
  position: relative;
}
.seafood {
  background-image: url('/src/images/seafoodHeader.svg');
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: 60% 60%;
  margin: auto;
}
.bakery {
  background-image: url('/src/images/bakeryHeader.svg');
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: 60% 60%;
  margin: auto;
}
.dairy {
  background-image: url('/src/images/dairyHeader.svg');
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: 60% 60%;
  margin: auto;
}
.fruits {
  background-image: url('/src/images/fruitHeader.svg');
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: 60% 60%;
  margin: auto;
}
.meat {
  background-image: url('/src/images/meatHeader.svg');
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: 60% 60%;
  margin: auto;
}
.nuts {
  background-image: url('/src/images/nutsSnacksHeader.svg');
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: 60% 60%;
}
.pantry {
  background-image: url('/src/images/pantryHeader.svg');
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: 60% 60%;
  margin: auto;
}
.veggies {
  background-image: url('/src/images/veggieHeader.svg');
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: 60% 60%;
  margin: auto;
}
.market {
  background-image: url('/src/images/marketHeader.jpg');
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: 60% 60%;
}
.row {
  display: flex;
  margin: 0;
}
.row .formInput {
  margin: 0 1%;
}
.billingAddress h5 {
  margin: 3% 0 0 1%;
}
.leaveReviewPage {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
  margin: 50px auto;
}
.leaveReviewPage .submitAll {
  margin: 50px 0;
}
.farmRatingDiv .farmLogoDiv .farmInfoDiv .hidden {
  display: none;
}
.farmRatingDiv .farmRatingHeading {
  margin: 15px 0;
  color: #989898;
}
.productRatingDiv {
  width: 100%;
}
.productRatingDiv .productImgDiv {
  display: flex;
}
.productRatingDiv .productImgDiv h6 {
  margin-left: 10px;
}
.productRatingDiv .productRatingHeading {
  margin: 15px 0;
  color: #989898;
}
.settingsButtons {
  display: flex;
}
.timePicker {
  padding-right: 175px;
  justify-content: space-between;
}
@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}
.Form {
  animation: fade 200ms ease-out;
}
.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: white;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #006f45;
  border-radius: 4px;
}
.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #006f45;
}
input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}
.FormRow:first-child {
  border-top: none;
}
.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: #494646;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #494646;
  /* Hack to hide the default webkit autofill */
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}
.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: #494646;
  background-color: transparent;
  animation: 1ms void-animation-out;
}
.FormRowInput::placeholder {
  color: #989898;
}
.StripeElement--webkit-autofill {
  background: transparent !important;
}
.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}
.SubmitButton {
  display: block;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: #f6a4eb;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
}
.SubmitButton:active {
  background-color: #d782d9;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}
.SubmitButton.SubmitButton--error {
  transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
  transform: scale(0.99) translateY(15px);
}
.SubmitButton:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: #7795f8;
  box-shadow: none;
}
.ErrorMessage {
  color: #eb5757;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}
.ErrorMessage svg {
  margin-right: 10px;
}
.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}
.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}
.ResultMessage {
  color: #494646;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}
.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}
.footerLinks {
  line-height: 1.7rem;
  display: flex;
  flex-direction: column;
}
.footerLinks a {
  text-decoration: none;
  font-size: 0.8rem;
  color: #0054bc;
}
.customerHomePageContainer {
  width: 80%;
  margin: auto;
}
.customerHomePageContainer .headerImagesBox {
  margin-top: 250px;
  display: flex;
  width: 100%;
  min-height: 450px;
  justify-content: space-between;
}
.customerHomePageContainer .headerImagesBox .leftImageBox {
  background-image: url(https://farmerafiles30112-staging.s3.us-east-2.amazonaws.com/hero_image_f87343.jpg);
  position: relative;
  height: 450px;
  width: 49%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: 100%;
}
.customerHomePageContainer .headerImagesBox .rightImageBox {
  background-image: url(https://farmerafiles30112-staging.s3.us-east-2.amazonaws.com/hero_image_xgfy8a.jpg);
  position: relative;
  height: 450px;
  width: 49%;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size: 100%;
}
.customerHomePageContainer .headerImagesBox .imageContent {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 220px;
  background: rgba(209, 204, 204, 0.5);
}
.customerHomePageContainer .headerImagesBox .imageContent h1 {
  margin: 20px 50px;
}
.customerHomePageContainer .headerImagesBox .imageContent h1 span {
  color: #fff;
}
.customerHomePageContainer .headerImagesBox .imageContent button {
  position: absolute;
  bottom: 30px;
  background: #fbd200;
  color: #494646;
  margin: 0 50px;
  width: 30%;
}
.customerHomePageContainer .featuredProducts {
  display: flex;
  justify-content: space-between;
}
.customerHomePageContainer .featuredProducts .productCard {
  min-width: 265px;
  width: 265px;
  height: 380px;
  margin: 20px 0;
  border-radius: 4px;
}
.customerHomePageContainer .featuredProducts .productCard .MuiButtonBase-root,
.customerHomePageContainer .featuredProducts .productCard .MuiButton-root {
  visibility: hidden;
}
.customerHomePageContainer .imageListContent {
  margin: 'auto';
  padding: 20px 30px;
  text-align: left;
}
.customerHomePageContainer .imageListContent h5 {
  margin-bottom: 10px;
}
.customerHomePageContainer .whatIsBox {
  background-color: #eaede9;
  padding: 5%;
  margin: 50px 0;
  width: 100%;
}
.customerHomePageContainer .whatIsBox h1 {
  text-align: center;
  margin-bottom: 5%;
}
.customerHomePageContainer .whatIsBox .whatIsBoxContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.customerHomePageContainer .whatIsBox .whatIsBoxContent .singleContentBox {
  width: 32%;
}
.customerHomePageContainer .whatIsBox .whatIsBoxContent .singleContentBox h6 {
  padding-bottom: 2%;
}
.emptyCartContainer {
  margin: 250px auto;
  max-width: 1200px;
}
.emptyCartContainer .emptyCartBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.emptyCartContainer .moreItemsContainer {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.emptyCartContainer .moreItemsBox {
  display: flex;
}
.emptyCartContainer .moreItemsBox .MuiCard-root {
  width: 265px;
  margin-right: 20px;
}
.emptyCartContainer .moreItemsBox .MuiCard-root .MuiBox-root {
  padding: 10px;
}
.emptyCartContainer .moreItemsBox .moreProductCard {
  width: 300px;
  height: 400px;
  margin-top: 20px;
}
.ordersContainer {
  margin: 230px auto;
  width: 100%;
  max-width: 1120px;
  display: flex;
}
.ordersContainer .farmOrderContainer {
  width: 740px;
}
.ordersContainer .farmOrderContainer .farmOrderDiv .css-92v238 {
  margin: 10px 0;
  width: 100%;
  border-top: 1px solid #bac2b7;
  border-bottom: 1px solid #bac2b7;
  padding: 10px 0;
}
.ordersContainer .farmOrderContainer .farmOrderDiv .MuiAvatar-root {
  width: 60px;
  height: 60px;
}
.ordersContainer .farmOrderContainer .farmOrderDiv .productsDiv {
  display: flex;
}
.ordersContainer .farmOrderContainer .farmOrderDiv .productsDiv .productInfoDiv {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  margin: 0 10px;
}
.ordersContainer .farmOrderContainer .farmOrderDiv .productsDiv .productInfoDiv .individualBox {
  width: 100%;
  padding: 5px;
}
.ordersContainer .farmOrderContainer .farmOrderDiv .productsDiv .productInfoDiv .individualBox .priceDiv {
  margin-left: 40%;
}
.ordersContainer .farmOrderContainer .farmOrderDiv .productsDiv .productsImageDiv .imageRow {
  width: 150px;
  display: flex;
}
.ordersContainer .farmOrderContainer .deliveryOptionsContainer {
  margin: 50px 0;
}
.ordersContainer .farmOrderContainer .MuiFormGroup-root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.ordersContainer .farmOrderContainer .MuiFormGroup-root .MuiCard-root {
  width: 350px;
  padding: 20px;
  margin: 10px 0;
}
.ordersContainer .orderSummaryWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #bac2b7;
  margin: 30px 40px;
  width: 30%;
  height: fit-content;
}
.ordersContainer .orderSummaryWrapper h4 {
  padding: 20px;
}
.ordersContainer .orderSummaryWrapper .totals {
  border-top: 1px solid #bac2b7;
  border-bottom: 1px solid #bac2b7;
}
.ordersContainer .orderSummaryWrapper .subtotal {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.ordersContainer .radioLabel {
  margin: 10px;
}
.checkoutPageBox {
  margin-top: 250px;
}
.checkoutPageBox .contentBox {
  display: flex;
}
.checkoutPageBox .contentBox .orderSummaryWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #bac2b7;
  margin: 30px 40px;
  width: 30%;
  height: fit-content;
}
.checkoutPageBox .contentBox .orderSummaryWrapper h4 {
  padding: 20px;
}
.checkoutPageBox .contentBox .orderSummaryWrapper .totals {
  border-top: 1px solid #bac2b7;
  border-bottom: 1px solid #bac2b7;
}
.checkoutPageBox .contentBox .orderSummaryWrapper .subtotal {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.checkoutPageBox .contentBox .orderSummaryWrapper .calculated {
  line-height: 20px;
}
.checkoutPageBox .contentBox .contactInfoBox {
  margin: 20px 0;
  width: 70%;
}
.checkoutPageBox .contentBox .contactInfoBox .row {
  display: flex;
  justify-content: space-between;
}
.checkoutPageBox .contentBox .deliveryInfoDiv {
  width: 70%;
  margin: 20px auto;
}
.checkoutPageBox .contentBox .deliveryInfoDiv .row {
  display: flex;
  justify-content: space-between;
}
.checkoutPageBox .contentBox .deliveryInfoDiv .radioGroup {
  width: 60%;
  margin: 20px 0;
}
.checkoutPageBox .contentBox .Form {
  width: 100%;
  margin: 20px;
}
.checkoutPageBox .contentBox .FormRowLabel {
  font-size: 16px;
}
.pickupButtonRow {
  display: flex;
  margin: 20px;
  width: 350px;
  justify-content: space-between;
}
.refundsContainer {
  margin: 150px 0 0 280px;
  width: 80%;
}
.refundsContainer .returns h3 {
  padding: 20px;
}
.refundsContainer .returns .ordersCustomerInfo {
  display: flex;
  background-color: rgba(186, 194, 183, 0.3);
  padding: 20px;
}
.refundsContainer .returns .ordersCustomerInfo .customerDiv {
  width: 40%;
}
.refundsContainer .returns .ordersCustomerInfo .orderInfoSmallDiv {
  display: flex;
  width: 60%;
  justify-content: space-between;
  margin-right: 100px;
}
.refundsContainer .returns .returnsInfo {
  display: flex;
  margin-bottom: 50px;
}
.refundsContainer .returns .returnsInfo .refundProducts {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
}
.refundsContainer .returns .returnsInfo .refundProducts p {
  width: 80%;
}
.refundsContainer .returns .returnsInfo .refundProducts .refundProductsLeft img {
  margin: 0 10px;
}
.refundsContainer .returns .returnsInfo .refundProducts .refundProductsRight {
  width: 60%;
}
.refundsContainer .returns .returnsInfo .refundProducts .refundProductsRight .refundProductsRightDiv {
  display: flex;
  justify-content: space-between;
}
.adminMainDiv {
  display: flex;
  width: 100%;
  margin-top: 120px;
  margin-left: 250px;
}
.adminMainDiv .adminDashboard {
  display: flex;
  flex-direction: column;
}
.adminMainDiv .adminDashboard .total-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px;
  width: 100%;
  height: 103px;
}
.adminMainDiv .adminDashboard .total-row .total-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 18px;
  position: static;
  width: 200px;
  height: 103px;
  left: 0px;
  top: 0px;
  /* Form field/Two */
  border: 1px solid #bac2b7;
  box-sizing: border-box;
  border-radius: 2px;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.adminMainDiv .adminDashboard .total-row .total-card p:first-of-type {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #989898;
}
.adminMainDiv .adminDashboard .total-row .total-card p:last-of-type {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  text-align: right;
  color: #494646;
}
.adminMainDiv .adminDashboard .chart-container {
  width: 1120px;
  padding-top: 30px;
}
.adminMainDiv .adminDashboard .chart-container .chart-row {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.adminMainDiv .adminDashboard .chart-container .chart-row .salesChart {
  width: 49%;
  margin: 0 10px;
  height: 360px;
  box-sizing: border-box;
  border-radius: 2px;
}
.adminMainDiv .adminDashboard .chart-container .chart-row .salesChart .salesChartTitle {
  margin: 15px 0px 0px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #989898;
}
.adminMainDiv .adminDashboard .chart-container .chart-row .new-users-chart {
  width: 49%;
  height: 360px;
  box-sizing: border-box;
  border-radius: 2px;
}
.adminMainDiv .adminDashboard .chart-container .chart-row .new-users-chart .new-users-title {
  margin: 15px 0px 0px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #989898;
}
.adminMainDiv .adminDashboard .chart-container .chart-row .bar-chart-sales {
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  min-width: 300px;
  margin: 0 10px;
  padding: 10px 15px;
  height: 360px;
}
.adminMainDiv .adminDashboard .chart-container .chart-row .bar-chart-sales div p {
  color: #989898;
}
.adminMainDiv .adminDashboard .chart-container .chart-row .bar-chart-ratings {
  height: 400px;
  box-sizing: border-box;
  border-radius: 2px;
  width: 49%;
  padding: 10px 15px;
}
.adminMainDiv .adminDashboard .chart-container .chart-row .bar-chart-ratings div p {
  color: #989898;
}
.adminMainDiv .adminDashboard .chart-container .chart-row .pie-chart {
  box-sizing: border-box;
  border-radius: 2px;
  width: 49%;
  margin: 0 10px;
  padding: 10px 15px;
  height: 360px;
}
.adminMainDiv .adminDashboard .chart-container .chart-row .pie-chart p {
  color: #989898;
}
.adminMainDiv .adminDashboard .chart-container .chart-row .donut-chart {
  box-sizing: border-box;
  border-radius: 2px;
  width: 49%;
  padding: 15px;
  height: 360px;
}
.adminMainDiv .adminDashboard .chart-container .chart-row .donut-chart div p:first-of-type {
  color: #989898;
  font-size: 16px;
}
.adminMainDiv #total-refunds {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.adminMainDiv #total-refunds p {
  margin-right: 10px;
}
.adminMainDiv #total-refunds p :last-of-type {
  color: #494646;
  font-size: 24px;
}
.adminMainDiv .farm-table-container {
  max-height: 100vh;
  overflow: scroll;
}
.adminMainDiv .admin-farm-container .go-back {
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #353535;
  cursor: pointer;
}
.adminMainDiv .admin-farm-container .column-container {
  margin: 20px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}
.adminMainDiv .admin-farm-container .column-container .farm-column {
  display: flex;
  margin: 0 20px;
  flex-direction: column;
  align-items: flex-start;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .farm-bio {
  width: 300px;
  height: 221px;
  padding: 10px;
  border: 1px solid #bac2b7;
  box-sizing: border-box;
  border-radius: 2px;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .farm-bio img {
  width: 48px;
  height: 48px;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .farm-bio .farm-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-top: 10px;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .farm-bio .bio-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .farm-bio .bio-row h6 {
  margin-right: 10px;
  font-weight: 600;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .farm-bio .bio-row p {
  color: #666666;
  font-weight: 400;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .farm-delivery-methods {
  width: 300px;
  height: fit-content;
  border: 1px solid #bac2b7;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .farm-delivery-methods h6 {
  margin-right: 5px;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .farm-delivery-methods p {
  margin-top: 5px;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .farm-refunds {
  width: 300px;
  height: fit-content;
  border: 1px solid #bac2b7;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .product-table-container {
  width: 100%;
  height: fit-content;
  overflow: scroll;
  min-width: 600px;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .salesChart {
  width: 100%;
  height: 350px;
  border: 1px solid #bac2b7;
  box-sizing: border-box;
  border-radius: 2px;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .salesChart .salesChartTitle {
  margin: 15px 0px 0px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #989898;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .farm-rating-chart {
  width: 100%;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .farm-rating-chart .farm-rating-overview {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  margin-top: 20px;
}
.adminMainDiv .admin-farm-container .column-container .farm-column .farm-rating-chart .farm-rating-card {
  display: flex;
}
.adminMainDiv .admin-farm-container .column-container .delivery-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}
.adminMainDiv .admin-farm-container .column-container .delivery-row h6 {
  font-weight: 700;
  margin-right: 10px;
}
.adminMainDiv .admin-farm-container .column-container .delivery-row p {
  color: #666666;
}
.adminMainDiv .admin-farm-container .column-container .box-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #989898;
}
.adminMainDiv .admin-farm-container .column-container .box-title .subtitle {
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: #353535;
}
.mb {
  margin-bottom: 15px;
}
.adminSideBar {
  flex-shrink: 0;
  z-index: 0;
  overflow-x: hidden;
}
.adminSideBar .MuiDrawer-paper {
  background-color: #eaede9;
  width: 230px;
}
.adminSideBar .MuiPaper-root {
  border-right: none;
}
.adminSideBar .MuiToolbar-root {
  margin-top: 50px;
}
.adminSideBar .MuiListItemIcon-root {
  max-width: 30px;
  min-width: 30px;
}
.adminSideBar .MuiTypography-root {
  font-size: 18px;
  color: #006F45;
}
.adminSideBar .MuiListItem-root {
  padding: 0;
  margin-top: 10px;
}
.adminSideBar .adminList {
  padding-left: 16px;
}
